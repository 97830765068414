const TEXTS_EN: any = {
	CODE: "EN",
	TITLE: "Aviastats by Aviasales",
	MENU_TICKETS_TEXT: "Special offers",
	MENU_TICKETS_LINK: "https://www.aviasales.ru/offers",
	MENU_HOTELS_TEXT: "Hotels",
	MENU_HOTELS_LINK: "https://www.aviasales.ru/#hotel",
	MENU_AUTO_TEXT: "Auto",
	MENU_AUTO_LINK: "https://www.rentalcars.com/?affiliateCode=aviasalesr191&adcamp=hp",
	MENU_INSURANCE_TEXT: "Insurance",
	MENU_INSURANCE_LINK: "https://www.cherehapa.ru/?marker=CPA&partnerId=9243",
	MENU_B2B_TEXT: "\u0414\u043b\u044f \u0431\u0438\u0437\u043d\u0435\u0441\u0430",
	MENU_B2B_LINK: "https://b2b.aviasales.ru",
	MENU_BLOG_TEXT: "Blog",
	MENU_BLOG_LINK: "https://www.aviasales.ru/blog",
	HERO_TITLE: "Aviastats",
	HERO_TEXT: "Follow insightful tips for revenue management & routes planning",
	TRY_OUT: "Try now",
	OFFER_TITLE: "In\u00A0what form will you receive our data?",
	OFFER_TEXT: "They\u2019re available in\u00A0two formats: Reports and Dashboards",
	OFFER_REPORTS_TITLE: "Reports",
	OFFER_REPORTS_TEXT: "Tables with non-personal data. Optimal for use in&nbsp;one&rsquo;s personal&nbsp;BI system",
	OFFER_DATA_TITLE: "Dashboards",
	OFFER_DATA_TEXT:
		"Data presented in&nbsp;convenient interactive reports. Suitable for carrying out routine tasks efficiently",
	CUSTOMERS_TITLE: "Fly High",
	CUSTOMERS_TEXT:
		"Strategic decisions could be empowered with comprehensive data available for our industry partners",
	CUSTOMERS_AIRLINE: "Airlines",
	CUSTOMERS_AIRPORT: "Airports",
	CUSTOMERS_OTA: "Online Travel Agencies",
	PROMO_TITLE: "Reliable Data From The Market Leader",
	PROMO_TEXT:
		"Aviastats&rsquo; data comes from Aviasales&nbsp;&mdash; one of&nbsp;the world&rsquo;s largest independent travel metasearch engines. With 18M&nbsp;MAU &amp;&nbsp;375 Boeing 737s filled with Aviasales&rsquo; customers on&nbsp;a&nbsp;daily basis, we&nbsp;provide the utmost business intelligence tool for comprehensive market analysis",
	BENEFITS_TITLE: "Enable Data-Driven Decisions With Aviastats",
	BENEFITS_TEXT: "Get the complete insights either with raw data or dashboards featuring:",
	BENEFITS_PULSE: "Trend reports & anomaly detection",
	BENEFITS_TRENDS: "Unserved routes forecast",
	BENEFITS_MONITORING: "Competitive intelligence",
	DEMO_TITLE: "Request A Demo",
	DEMO_TEXT: "And get all the data you need",
	DEMO_PLACEHOLDER: "Enter your email",
	FOOTER_VKONTAKTE: "VK",
	FOOTER_VKONTAKTE_LINK: "https://vk.com/aviasales",
	FOOTER_TELEGRAM: "Telegram",
	FOOTER_TELEGRAM_LINK: "https://t.me/aviasales",
	FOOTER_TIKTOK: "TikTok",
	FOOTER_TIKTOK_LINK: "https://www.tiktok.com/@aviasales",
	FOOTER_TWITTER: "Twitter",
	FOOTER_TWITTER_LINK: "https://twitter.com/aviasales",
	FOOTER_DZEN: "Dzen",
	FOOTER_DZEN_LINK: "https://dzen.ru/aviasales",
	FOOTER_VIBER: "Viber",
	FOOTER_VIBER_LINK: "https://invite.viber.com/?g2=AQAtX3IQDoKIAkjNZmzOMFaqi9OHuUKPrggGpt4kfnukhlsf0gueU8hnc6dLdcqt",
	FOOTER_YOUTUBE: "YouTube",
	FOOTER_YOUTUBE_LINK: "https://www.youtube.com/aviasales",
	FOOTER_PSGR: "Journal",
	FOOTER_PSGR_LINK:
		"https://www.aviasales.ru/psgr?utm_content=blog_psgr&utm_source=aviasales&utm_campaign=psgr&utm_medium=aviastats",
	FOOTER_WORK_LINK: "https://www.aviasales.ru/about/work",
	FOOTER_AD_LINK: "https://ad.aviasales.ru",
	FOOTER_REFERRAL_LINK: "https://www.aviasales.ru/affiliateprogram",
	FOOTER_ABOUT_LINK: "https://www.aviasales.ru/about",
	FOOTER_NEWSROOM: "Newsroom",
	FOOTER_NEWSROOM_LINK: "https://newsroom.aviasales.com/",
	FOOTER_SUPPORT_LINK: "https://www.aviasales.ru/mag/how-to",
	FOOTER_RULES_LINK: "https://www.aviasales.ru/terms-of-use",
	FOOTER_ABOUT: "About",
	FOOTER_REFERRAL: "Affiliate program",
	FOOTER_AD: "Promo",
	FOOTER_WORK: "Vacancies",
	FOOTER_SUPPORT: "Support",
	FOOTER_RULES: "Regulations",
	FOOTER_COPYRIGHT: "\u00a9 2007\u20132024, Aviasales \u2014 cheap airline tickets",
	DATA_TITLE: "What data do we have?",
	DATA_SUBTITLE: "Analytical data relating to the aviation industry in Russia and CIS countries:",
	DATA_PASSANGERS_TITLE: "Passenger traffic",
	DATA_PASSANGERS_TEXT_1: "Full data for Russian passenger air traffic",
	DATA_PASSANGERS_TEXT_2: "Available in real time in LEG and O&D formats",
	DATA_PASSANGERS_TEXT_3:
		"Allows you to evaluate not only passenger traffic, but also airline revenue for various flight routes",
	DATA_PRICE_TITLE: "Prices",
	DATA_PRICE_TEXT_1: "Competitor airline pricing data can help you optimize your pricing",
	DATA_PRICE_TEXT_2: "You can study them manually in interactive reports or import them to an RMS",
	DATA_SEARCH_TITLE: "Searches and clicks",
	DATA_SEARCH_TEXT: "Allow you to study demand for various routes"
};
export default TEXTS_EN;
