import './Footer.sass'
import React, { FC } from 'react'
import useI18n from '../../misc/i18n/useI18n'
import I18nService from '../../misc/i18n/I18nService'
import Lang from '../../misc/i18n/Lang'

const Footer: FC = () => {
    const texts = useI18n()
    const SOCIALS: Array<{ key: string, title: string, href: string }> = [
        {
            key: 'vkontakte',
            title: texts.FOOTER_VKONTAKTE,
            href: texts.FOOTER_VKONTAKTE_LINK,
        },
        {
            key: 'telegram',
            title: texts.FOOTER_TELEGRAM,
            href: texts.FOOTER_TELEGRAM_LINK,
        },
        {
            key: 'twitter',
            title: texts.FOOTER_TWITTER,
            href: texts.FOOTER_TWITTER_LINK,
        },
        {
            key: 'tiktok',
            title: texts.FOOTER_TIKTOK,
            href: texts.FOOTER_TIKTOK_LINK,
        },
        {
            key: 'dzen',
            title: texts.FOOTER_DZEN,
            href: texts.FOOTER_DZEN_LINK,
        },
        {
            key: 'viber',
            title: texts.FOOTER_VIBER,
            href: texts.FOOTER_VIBER_LINK,
        },
        {
            key: 'youtube',
            title: texts.FOOTER_YOUTUBE,
            href: texts.FOOTER_YOUTUBE_LINK,
        },
        {
            key: 'psgr',
            title: texts.FOOTER_PSGR,
            href: texts.FOOTER_PSGR_LINK,
        },
    ]

    const MENU: Array<{ title: string, href: string }> = [
        { title: texts.FOOTER_ABOUT, href:  texts.FOOTER_ABOUT_LINK},
        { title: texts.FOOTER_REFERRAL, href:  texts.FOOTER_REFERRAL_LINK},
        { title: texts.FOOTER_AD, href:  texts.FOOTER_AD_LINK},
        { title: texts.FOOTER_NEWSROOM, href:  texts.FOOTER_NEWSROOM_LINK},
        { title: texts.FOOTER_WORK, href:  texts.FOOTER_WORK_LINK},
        { title: texts.FOOTER_SUPPORT, href:  texts.FOOTER_SUPPORT_LINK},
        { title: texts.FOOTER_RULES, href:  texts.FOOTER_RULES_LINK},
    ]

    return (
        <footer className='footer'>
            <a href='/' className='footer__logo'>b2b.aviasales.ru</a>
            <div className='footer__socials'>
                {SOCIALS.map((item) => (
                    <a key={item.key} target='_blank' rel='noreferrer noopener' href={item.href} className={`footer__social footer__social_${item.key}`}>
                        <span className={`footer__social-icon footer__social-icon_${item.key}`} />
                        <span className='footer__social-text'>{item.title}</span>
                    </a>
                ))}
            </div>
            <nav className='footer__menu'>
                {MENU.map((item) => (
                    <a key={item.href} className='footer__menu-item' target='_blank' href={item.href} rel='noreferrer noopener'>
                        {item.title}
                    </a>
                ))}
            </nav>
            <p className='footer__copyright'>{texts.FOOTER_COPYRIGHT}</p>
            <button
                className={`footer__lang ${texts.CODE === Lang.RU ? 'en' : 'ru'}`}
                onClick={() => I18nService.changeLang(texts.CODE === Lang.RU ? Lang.EN : Lang.RU)}
            >
                {texts.CODE.toLowerCase() === 'en' ? 'Русский' : 'English'}
            </button>
        </footer>
    )
}

export default Footer


